.news-card {
    height: 100%;
    min-width: 100%;
}

.news-card-left-side {
    width: 50%;
    height: 100%;
    max-width: 850px;
    min-width: 700px;
    background-color: var(--green-bottle);
    mix-blend-mode: hard-light;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px 100px;
}

.news-card-date {
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.news-card-title {
    color: white;
    font-size: 50px;
    font-weight: 700;
    word-wrap: break-word;
    line-height: 50px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.news-card-subtitle {
    color: white;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
    margin-top: 25px;
    text-transform: uppercase;
}

.news-card-counter {
    font-size: 52px;
    color: white;
    font-weight: 700;
    position: absolute;
    bottom: 0;
}

.news-card-button {
    margin-top: 50px;
    width: 125px;
    height: 40px;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    text-align: center;
    line-height: 38px;
    text-transform: uppercase;
    transition: all 0.3s ease-out;
}

.news-card-button:hover {
    border-radius: 2rem;
}

@keyframes newsSlider {
    0% { opacity: 0;}
    100% { opacity: 1;}
}

@media screen and (max-width: 960px) {

    .news-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .news-card-left-side {
        min-width: 0;
        width: 100%;
        height: 60%;
        padding: 5px 5px 5px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .news-card-title {
        font-size: 26px;
        line-height: 25px;
        font-weight: 400;
    }

    .news-card-subtitle {
        font-size: 18px;
        margin-top: 10px;
    }

    .news-card-button {
        margin-top: 15px;
    }

    .news-card-counter {
        font-size: 36px;
        right: 10px;
        font-weight: 500;
    }

}