.standard-button {
    height: 40px;
    width: fit-content;
    min-width: 175px;
    border: 2px solid white;
    text-transform: uppercase;
    color: white;
    letter-spacing: 4px;
    padding: 0 20px;
    font-weight: 700;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s ease-in-out
}

.standard-button:disabled {
    border-color: rgba(191, 191, 191,0);
    color: rgb(191, 191, 191);
    cursor: default;
}

.standard-button:hover {
    border-radius: 20px;
}

.standard-button:disabled:hover {
    border-radius: 0px;
}

.round-button {
    height: 40px;
    width: fit-content;
    min-width: 175px;
    border: 2px solid white;
    text-transform: uppercase;
    color: white;
    letter-spacing: 4px;
    padding: 0 20px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 20px;
    background-color: transparent;
    transition: all 0.3s ease-in-out
}

.round-button:disabled {
    border-color: rgb(199, 199, 199);
    color: rgb(199, 199, 199);
    cursor: default;
}

.round-button:hover {
    padding: 0 70px ;
}

.round-button:disabled:hover {
    padding: 0 20px ;

}