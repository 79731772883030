.vr-window-content {
    height: 600px;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
}

.vr-window-top-bar {
    height: 35px;
    background-color: var(--medium-beige);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.vr-window-top-bar-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60px;
}

.vr-window-top-bar-action > svg:hover {
    fill: var(--dark-beige);
    cursor: pointer;
}

.vr-window-top-bar > p {
    color: var(--very-light-beige);
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 18px;
}

.vr-window-iframe {
    width: 100%;
    flex: 1 1;
}