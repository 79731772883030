.projects-main-div {
    height: calc(100vh - 200px);
    min-height: 600px;
    overflow-x: hidden;
}

.projects-main-div-mobile {
    display: none;
}

.react-horizontal-scrolling-menu--wrapper  {
    height: inherit;
    min-height: 600px;
}

.react-horizontal-scrolling-menu--scroll-container  {
    height: 100% !important;
    padding-bottom: 10px;
}

@media screen and (max-width: 960px) {
    
    .projects-main-div {
        display: none;
    }

    .projects-main-div-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: fit-content;
        min-height: calc(100vh - 200px);
    }

}