.footer-container > * {
  letter-spacing: 3px;
}

.footer-am-logo {
    width: 150px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.footer-social > * {
    height: 30px;
    width: auto;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.footer-social {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 35px;
    gap: 10px;
}

.footer-container {
    height: 100px;
    display: flex;
    background-color: white;
    /* justify-content: space-between; */
    align-items: center;
    position: static; /* static for only showing on the bottom of the page, sticky for always showing the footer */
    width: 100vw;
    padding: 0 35px;
    bottom: 0;
    z-index: 900;
    gap: 40px;
}

.footer-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    gap: 10px;
    flex-direction: column;
    width: 160px;
}

.footer-left-side {
    width: 255px;
    margin-right: auto;
}

.footer-left-side > * {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.footer-left-side:hover > p {
    transition: all 0.1s ease-in-out;
    font-weight: 700;
}

.footer-left-side:hover > p > span {
    font-weight: 300;
    transition: all 0.1s ease-in-out;
}

.footer-left-side > p > span {
    font-weight: 700;
}

.footer-social > img:hover {
    height: 35px;
    transition: height 0.1s ease-in-out;
}

.cofinanciamento-logo {
    filter: brightness(0) saturate(100%);
    height: 85%;
}

.cofinanciamento-logo-mobile {
    display: none;
}

@media screen  and (max-width: 1300px) {
    .cofinanciamento-logo {
        display: flex;
        height: 60%;
    }

    .footer-left-side {
        margin-right: 0;
        width: min-content;
    }

    .footer-container {
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 960px) {
    .footer-container {
        flex-direction: column;
        padding: 10px 5px;
        height: 150px;
        gap: 15px;
        justify-content: space-evenly;
    }

    .footer-right-side {
        width: 100%;
        height: fit-content;
        justify-content: center;
        flex-direction: row;
        gap: 20px;
    }

    .footer-am-logo {
        width: 150px;
    }

    .footer-am-logo:hover {
        width: 150px;
    }

    .footer-social > img {
        height: 30px;
    }

    .footer-social > img:hover {
        height: 30px;
    }

    .cofinanciamento-logo-mobile {
        display: flex;
        filter: brightness(0) saturate(100%);
        height: 50px;
        max-width: 100%;
    }

    .cofinanciamento-logo {
        display: none;
    }

    .footer-left-side {
        width: max-content;
    }
}
