.navbar {
    background: white;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    position: sticky; /* set to fixed to always shown on screen, need to add width: 100vw aswell */
    top: 0;
    z-index: 900;
}

.navbar-logo {
    left: 0;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    max-width: 400px;
    min-width: 200px;
}

.logo {
    width: 85%;
    margin-left: 30px;
    height: auto;
    border-style: none;
    transition: all 0.2s ease-in-out;
}

.logo:hover {
    width:90% ;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60%;
    background-color: white;
}

.navbar-container-clicked {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 60%;
    background-color: white;
}

.nav-menu {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    list-style: none;
    margin-right: 20px;
}

.menu-icon, .menu-icon-clicked {
    display: none;
}

.navbar-itens {
    display: flex;
    width: 20%;
}

.navbar-item {
    color: rgb(88,86,69);
    text-decoration: none;
    padding: 10px 15px 0px 15px;
    letter-spacing: 3px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}

.navbar-item:hover {
    color: black;
    font-weight: 700;
}

.navbar-item > .navbar-item-border { 
    filter: opacity(0)
}

.navbar-item-active {
    color: black;
    text-decoration: none;
    padding: 10px 15px 0px 15px;
    font-weight: 700;
    letter-spacing: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}

.navbar-item-border {
    transition: all 0.35s ease-in-out;
}

.navbar-item-active > .navbar-item-border,
.navbar-itens:hover .navbar-item-border { 
    filter: opacity(1);
}

.navbar-button-itens {
    margin: 0 10px;
    display: flex;
}

.navbar-button-img {
    width: 30px;
}

.navbar-button-itens-mobile {
    opacity: 0;
    z-index: -1;
    position: absolute;
}

.navbar-button-itens-mobile-active {
    opacity: 0;
    z-index: -1;
    position: absolute;
}

.navbar-button-lupa, .navbar-button-compass {
    transition: all 0.5s ease-in-out !important;
}

.navbar-button-compass:hover {
    transform: rotate(180deg) scale(1.1);
}

.navbar-button-lupa:hover {
    transform: scale(1.1);
}

.search-black-div {
    position: absolute;
    background-color: rgba(0,0,0,1);
    width: 50px;
    height: 150px;
    top: 0; 
    right: 64px;
    transition: all 0.3s ease-in-out;
    z-index: 903;
}

.search-black-div-hidden {
    position: absolute;
    width: 50px;
    top: 0; 
    right: 64px;
    height: 0;
    transition: all 0.3s ease-in-out;
    z-index: 903;
}

.search-black-innerdiv {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.search-black-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.modal-background-div {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 901;
    background-color: rgba(0,0,0,0.0);
    top: 0
}

.search-input {
    position: absolute;
    top: 100px;
    z-index: 902;
    right: 114px;
    height: 50px;
    width: 200px;
    background-color: rgba(0,0,0,0.7);
    color: white;
    text-indent: 5px;
    font-size: 16px;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
}

.search-input::placeholder {
    color: var(--very-light-beige);
}  

.search-input-hidden {
    position: absolute;
    top: 100px;
    z-index: 902;
    right: 120px;
    height: 50px;
    width: 0px;
    background-color: rgba(0,0,0,0.7);
    border: none;
    transition: all 0.3s ease-in-out;
}

.lang-black-div {
    position: absolute;
    background-color: rgba(0,0,0,1);
    width: 50px;
    height: 100px;
    top: 0; 
    right: 18px;
    transition: all 0.3s ease-in-out;
    z-index: 903;
}

.lang-black-div-hidden {
    position: absolute;
    width: 50px;
    top: 0; 
    right: 18px;
    height: 0;
    transition: all 0.3s ease-in-out;
    z-index: 903;
}

.lang-black-innerdiv {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.lang-black-menu {
    background-color: rgba(0,0,0,0.9);
    width: 150px;
    height: 75px;
    position: absolute;
    right: 0px;
    transition: all 0.3s ease-in-out;
}

.lang-black-menu-hidden {
    width: 100px;
    height: 0;
    position: absolute;
    top: 100px;
    right: 0;
    transition: all 0.3s ease-in-out;
}

.about-black-div {
    width: 121px;
    position: absolute;
    height: 0px;
    margin-left: 25px;
    top: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: all 0.3s ease-in-out;
}

.about-black-div > a {
    color: white;
    font-size: 0px;
    text-align: center;
    background-color: rgba(0,0,0,0.9);
    height: 0px;
    width: 100%;
    text-decoration: none;
    letter-spacing: 1px;
    cursor: pointer;
    transition: letter-spacing 0.2s ease-in-out;
}

.about-black-div > a:hover {
    letter-spacing: 2px;
}

.navbar-itens:hover .about-black-div {
    height: 120px;
}

.navbar-itens:hover .about-black-div > a {
    height: 35px;
    font-size: 12px;
}

@media screen and (min-width: 1700px) {
    .search-black-div {
        right: 74px;
    }

    .search-black-div-hidden {
        right: 74px
    }

    .search-input {
        right: 124px;
    }

    .lang-black-div {
        right: 28px;
    }

    .lang-black-div-hidden {
        right: 28px
    }
}

@media screen and (max-width:1300px) and (min-width: 960px) {

    .navbar {
        flex-direction: column;
        height: 175px;
    }

    .navbar-logo {
        margin: 25px 0px 15px 0px
    }

    .navbar-container, .navbar-container-clicked {
        width: 100%;
    }

    .lang-black-menu-hidden {
        top: 75px
    }

    .lang-black-div {
        top: 100px;
        height: 75px;
        right: 27px;
    }
    .lang-black-div-hidden {
        top: 100px;
        right: 27px;
    }

    .lang-black-innerdiv {
        height: 75px;
    }

    .search-black-div {
        top: 104px;
        right: 75px;
        height: 125px;
    }

    .search-black-div-hidden {
        top: 104px;
        right: 75px;
    }

    .search-black-innerdiv {
        height: 75px;
    }

    .search-input {
        right: 125px;
        top: 179px
    }

    .search-input-hidden {
        top: 179px;
        right: 125px;
    }

    .about-black-div {
        top: 160px
    }

}

@media screen and (max-width: 960px) {

    .menu-icon {
        display: block;
        top: 0;
        right: 0;
        transform: scale(1.5);
    }

    .menu-icon-clicked {
        display: block;
        top: 0;
        right: 0;
        transform: scale(1.5);
    }

    .menu-icon-clicked > button {
        color: white;
    }

    .menu-icon > button {
        color: black;
    }

    .navbar-logo {
        width: 80%;
    }

    .navbar-container {
        width: 20%;
    }

    .navbar-container-clicked {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 75px;
        background-color: #191e19;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 100px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: rgb(255, 255, 255);
        left: 0;
        top: 100px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        justify-content: flex-start;
        height: 150px;
    }

    .navbar-itens {
        text-align: left;
        width: 80%;
    }

    .navbar-item {
        font-size: 13px;
    }

    .navbar-button-itens {
        display: none;
    }

    .navbar-button-itens-mobile-active {
        display: flex;
        opacity: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 100px;
        z-index: 999;
        transition: all 0.5s ease-in-out;
        background-color: #f0f0f0;
        width: 75px;
        height: 150px;
    }

    .navbar-button-itens-mobile {
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        transition: top 0.5s ease-in-out;
    }

    .navbar-button-itens-mobile-active > * {
        margin: 0px 5px
    }

    .navbar-item-border {
        display: none;
    }

    .search-black-div {
        position: absolute;
        background-color: rgba(0,0,0,1);
        width: 75px;
        height: 200px;
        top: 100px; 
        right: 0px;
        transition: all 0.3s ease-in-out;
        z-index: 903;
    }

    .search-black-div-hidden {
        position: absolute;
        width: 75px;
        top: 100px; 
        right: 0;
        height: 0;
        transition: all 0.3s ease-in-out;
        z-index: 903;
    }
    
    .search-black-innerdiv {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
    }
    
    .search-input {
        position: absolute;
        top: 250px;
        z-index: 902;
        right: 75px;
        height: 50px;
        width: calc(100% - 75px);
        background-color: rgba(0,0,0,1);
        color: white;
        text-indent: 5px;
        font-size: 16px;
        outline: none;
        border: none;
        transition: all 0.3s ease-in-out;
    }
    
    .search-input-hidden {
        position: absolute;
        top: 250px;
        z-index: 902;
        right: 75px;
        height: 50px;
        width: 0px;
        background-color: rgba(0,0,0,0.7);
        border: none;
        transition: all 0.3s ease-in-out;
    }

    .lang-black-div {
        position: absolute;
        background-color: rgba(0,0,0,1);
        width: 75px;
        height: 200px;
        top: 100px; 
        right: 0px;
        border-bottom-right-radius: 0.5rem;
        transition: all 0.3s ease-in-out;
        z-index: 903;
    }
    
    .lang-black-div-hidden {
        position: absolute;
        width: 75px;
        top: 100px; 
        right: 0px;
        height: 0px;
        transition: all 0.3s ease-in-out;
        z-index: 903;
    }
    
    .lang-black-innerdiv {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
    }
    
    .lang-black-menu {
        background-color: rgba(0,0,0,1);
        width: 175px;
        height: 100px;
        position: absolute;
        right: 0px;
        top: 150px;
        transition: all 0.3s ease-in-out;
    }
    
    .lang-black-menu-hidden {
        width: 0;
        height: 100px;
        position: absolute;
        right: 0px;
        top: 150px;
        transition: all 0.3s ease-in-out;
    }

    .navbar-itens:hover .about-black-div {
        height: 0px;
    }

    .navbar-itens:hover .about-black-div > a {
        height: 0px;
        font-size: 0px;
    }
    
}

