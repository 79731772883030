* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Assistant', sans-serif;
  /* overflow-x: hidden; */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

:root {
  --green-bottle: #1d4225;
  --dark-beige: #61634f;
  --medium-beige: #b3a698;
  --light-beige: #bdb4aa;
  --very-light-beige: #e5e1de;
}