.search-main-div {
    height: calc(100vh - 200px);
    width: 100vw;
    min-height: 350px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.search-title {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

.search-results-div {
    flex: 1 1;
    width: 75%;
    padding: 25px 50px;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-results-div::-webkit-scrollbar {
    width: 10px;
}

.search-results-div::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.search-results-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.5);
    background-clip: content-box;
}

.search-results-div::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: rgba(255,255,255,0.75);
}

@media screen and (max-width: 960px) {

    .search-title {
        font-size: 18px;
    }

    .search-results-div {
        padding: 25px 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search-results-div::-webkit-scrollbar {
        width: 0;
    }

}