.confirmation-dialog-content {
    height: 300px;
    width: 600px;
    display: flex;
    padding: 10px 40px !important;
    flex-direction: column;
}

.confirmation-dialog-middle {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmation-dialog-icon {
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmation-dialog-text {
    width: 66%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--dark-beige);
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 500;
    padding-left: 20px;
    white-space: pre-line;
}

.confirmation-dialog-action {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}