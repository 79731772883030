.news-info-main-div {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    scroll-behavior: smooth;
}

.news-info-info {
    height: 100%;
    width: 74%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-right: 10px;
    animation: newsInfo 0.7s ease-in-out;
}

.news-info-left-div {
    width: 55%;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: absolute;
    bottom: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
}

.news-info-background {
    width: 55%;
    background-color: var(--green-bottle);
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: absolute;
    bottom: 0;
    top: 0;
    mix-blend-mode: hard-light;
    transition: all 0.5s ease-in-out; 
}

.news-info-left-div-title {
    color: white;
    font-size: 40px;
    font-weight: 700;
    word-wrap: break-word;
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.news-info-left-div-location {
    color: white;
    margin-bottom: 45px;
}

.news-info-left-div-description {
    color: white;
    flex: 1 1;
    white-space: pre-wrap;
    text-shadow: 0 0 white;
}

.news-info-left-div-bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
}

.news-info-recommended {
    width: 24%;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: scroll;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    transition: all 0.5s ease-in-out;
    animation: recommended 1s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.news-info-recommended-title {
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

@keyframes newsInfo {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes recommended {
    0% {
        transform: translateY(-500px);
        opacity: 0;
    }
    100% {
        transform: translateY(-0px);
        opacity: 1;
    }
}

@media screen and (max-width:1300px) {
    .news-info-left-div {
        width: 100%;
    }

    .news-info-background {
        width: 100%;
    }

    .news-info-recommended {
        width: 29%;
    }

    .news-info-info {
        width: 69%;
    }
}

@media screen and (max-width:960px) {
    .news-info-left-div {
        width: 100%;
    }

    .news-info-background {
        display: none;
    }

    .news-info-main-div {
        flex-direction: column;
        height: fit-content;
    }

    .news-info-recommended {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 10px;
        margin-top: 20px;
    }

    .news-info-recommended-title {
        font-size: 18px;
    }

    .news-info-info {
        width: 100%;
        height: fit-content;
        background-position: top;
        background-size: contain;
    }

    .news-info-left-div {
        height: fit-content;
        position: relative;
        padding: 10px;
        margin-top: 235px;
        background-color: var(--green-bottle);
    }

    .news-info-left-div-title {
        font-size: 30px;
        line-height: 30px;
        text-align: center;
    }

    .news-info-left-div-location {
        text-align: center;
    }

    .news-info-left-div-description {
        text-align: justify;
    }

    .news-info-left-div-bottom {
        justify-content: space-evenly;
        align-items: center;
    }
}