.projects-card-div {
    margin-right: 20px;
    /* width: 450px; */
    min-width: 450px;
    height: 100%;
    min-height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: width 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    opacity: 0;
    animation: projectCardRise 0.7s ease-in-out forwards;
    box-shadow: 2px 0px 4px 0px rgb(0 0 0 / 50%);
    /* box-shadow: inset 0px 0px 9px 4px rgb(0 0 0 / 50%) */
}

.card-info-text {
    opacity: 0;
    width: 80%;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.card-info {
    opacity: 0;
    width: 100%;
    height: 20%;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-info-title, .card-info-subtitle {
    text-align: center;
    opacity: 0;
    color: white;
    letter-spacing: 3px;
    text-transform: uppercase;
    user-select: none;
}

.card-info-text-text {
    font-weight: 400;
    font-size: 18px;
    color: white;
    text-shadow: 1px 1px 0px black;
    text-align: justify;
}


.card-info-title {
    font-weight: 600;
    font-size: 24px;
    max-width: 300px;
}

.card-info-subtitle {
    font-size: 16px;
}

.projects-card-div:hover .card-info-text {
    opacity: 1;
    width: 80%;
    height: fit-content;
    max-height: 40%;
    min-height: 100px;
    user-select: none;
}

.projects-card-div:hover .card-info {
    opacity: 1;
    width: 66%;
    height: 20%;
    min-height: 100px;
    border: 3px solid white;
    border-radius: 20px;
    user-select: none;
    animation: boxanimation 5s infinite ease;
}

.projects-card-div:hover .card-info > p {
    opacity: 1;
    transition: all 1s ease-in-out;
}

.projects-card-div:hover {
    transform: scaleY(1.1);
    cursor: pointer;
}

.projects-card-div:hover .card-black-div {
    opacity: 1;
}

.card-black-div {
    opacity: 0;
    height: 100%;
    width: 100%;
    background-color: var(--green-bottle);
    mix-blend-mode: hard-light;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    padding: 30px 5px;
    transition: all 0.3s ease-in-out;
}

@keyframes boxanimation {
    0%, 100% {border-radius: 20px}
    50% {border-radius: 10px}
}

@keyframes projectCardRise {
    0% {
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {
    
    .projects-card-div {
        width: 100%;
        height: auto;
        min-height: 250px;
        margin: 5px 0;
        border-radius: 5px;
        align-items: flex-end;
        min-width: 0;
    }

    .projects-card-div:hover {
        width: 100%;
    }

    .card-black-div {
        opacity: 1;
        border-radius: 5px;
    }

    .card-info {
        opacity: 1;
        width: 100%;
    }

    .projects-card-div:hover .card-info {
        width: 100%;
        border: none;
    }

    .card-info-title, .card-info-subtitle {
        opacity: 1;
    }

    .card-info-text {
        display: none;
    }
}