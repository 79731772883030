.apoio-dialog-content {
    height: 650px;
    width: 700px;
    overflow-y: unset !important;
}

.dialog-top-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.dialog-top-card-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialog-top-card-header-button {
    height: 65%;
    width: 125px;
    border: 2px solid #b3a698;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.dialog-top-card-header-button:hover {
    border-radius: 2rem;
}

.MuiCardHeader-action {
    margin: 0;
    height: 60px;
    align-self: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.helper-div {
    display: flex;
    flex-direction: column;
}

.helper-inner-div {
    height: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.helper-card {
    height: 100px;
    width: 95%;
    margin: 10px 5px;
    min-height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.helper-card-logo {
    width: 20%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.helper-card-logo > img {
    max-width: 100%;
    max-height: 100%;
}

.helper-card-info {
    height: 95%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.helper-inner-div::-webkit-scrollbar {
    width: 10px;
}

.helper-inner-div::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.helper-inner-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.5);
    background-clip: content-box;
}

.helper-inner-div::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: rgba(255,255,255,0.75);

}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.close-button:hover {
    font-size: 28px;
}

.help-dialog-no-contributions-text {
    margin: 50px 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-beige);
}

.help-dialog-no-contributions-div {
    width: 100%;
    height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .apoio-dialog-content {
        height: 550px;
        width: 100vw;
    }

    .helper-inner-div {
        height: fit-content;
        overflow-y: visible;
    }

    .helper-card {
        height: 125px;
        width: 100%;
        margin: 10px 2px;
    }
    
    .dialog-top-card-header {
        height: 100px;
    }
    
    .dialog-top-card-header-button {
        height: 100%;
        width: 75px;
        text-align: center;
    }

    .dialog-top-card-header-button:hover {
        width: 75px;
    }

}