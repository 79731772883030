.newsletter-dialog-content {
    width: 350px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.newsletter-dialog-title {
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 700;
}

.newsletter-dialog-subtitle {
    color: var(--dark-beige);
    margin: 5px 0 15px
}

.newsletter-dialog-content > form {
    width: 100%;
    display: flex;
    gap: 20px;
}

.newsletter-input {
    width: 100%;
    height: 30px;
    border: 1px solid var(--green-bottle);
    border-radius: 5px;
    padding-left: 5px;
}

.newsletter-button {
    appearance: none;
    border: 1px solid var(--green-bottle);
    border-radius: 5px;
    color: var(--green-bottle);
    background-color: transparent;
    padding: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.newsletter-button:hover,
.newsletter-button:focus {
    background-color: var(--green-bottle);
    color: white;
    outline: none;
}

.newsletter-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--green-bottle);
    cursor: pointer;
}

.newsletter-dialog-message {
    color: red;
    margin-top: 10px;
}