.main-apoio-card-div {
    height: 125px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
    user-select: none;
    animation: taskCardRise 0.7s ease-in-out;
}

.upper-div {
    height: 80%;
    width: 100%;
    display: flex;
    position: relative;
    border: 3px solid #b3a698;
    border-radius: 10px;
    box-shadow: 6px 3px 5px -2px #888;
}

.upper-div:hover .upper-black-div {
    opacity: 0.9 !important;
    cursor: pointer;
}

.upper-black-div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.upper-black-div > p {
    color: white;
    font-size: 22px;
    font-weight: 600;
    user-select: none;
    width: 70%;
    letter-spacing: 2px;
    text-align: left;
}

.left-side {
    width: 25%;
    height: 100%;
    background-color: #b3a698;
    box-shadow: 4px 0 6px 0px #888;
}

.left-side-text {
    width: 100%;
    height: 40%;
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.left-side-quantity {
    height: 60%;
    width: 100%;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    color: white;
}

.right-side {
    width: 100%;
    height: 100%;
    display: flex;
}

.right-side-icon {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-side-icon > img {
    width: 65px;
    height: 65px;
}

.right-side-text {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.right-side-text > p {    
    color: #b3a698;
    font-weight: 600;
    font-size: 18px;
    padding: 5px;
    text-align: left;
    line-height: 22px;
}

.bottom-div {
    height: 17%;
    width: 100%;
    background-color: #b3a698;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 0px #888;
}

.bottom-div-percentage {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 30%, rgba(0,0,0,0.7483368347338936) 36%, rgba(0,0,0,0.48503151260504207) 41%, rgba(0,0,0,0.7539390756302521) 46%, rgba(0,0,0,1) 53%);
    background-size: 300% 100%;
    border-radius: 5px;
    height: 100%;
    max-width: 100%;
    color: white;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 14px;
    padding-right: 5px;
    user-select: none;
    transition: all 1s ease-in-out;
    animation: percentageBar 5s infinite ease;
}

.bottom-div-percentage-small {
    background-color: black;
    border-radius: 5px;
    height: 100%;
    max-width: 100%;
    color: white;
    font-size: 14px;
    text-indent: 5px;
    user-select: none;
    transition: all 1s ease-in-out;
}

@keyframes percentageBar {
    0% {
        background-position: 100% 50%;
    }
    50% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes taskCardRise {
    0% { 
        transform: scale(0.5);
        opacity: 0;
    }
    100% { 
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {

    .main-apoio-card-div {
        margin: 10px 10px;
    }

    .right-side-text > p {
        font-size: 16px;
        line-height: 16px;
    }

    .right-side-text {
        width: 70%;
    }
}