.main-share-dialog-div {
    display: flex;
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    border-radius: 10px;
}

.main-share-dialog-div > * {
    margin-right: 5px;
}

.main-share-dialog-div > *:hover {
    filter: opacity(0.8);
}