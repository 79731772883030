.emaildialog-main-div {
    min-height: 600px;
}

.emaildialog-content-div {
    min-height: 600px;
    padding: 50px 10px;
    background-image: url('../../assets/apoiar/apoioar_background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.emaildialog-form-div {
    height: 90%;
    width: 50%;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emaildialog-text-div {
    height: 90%;
    width: 40%;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;
    padding-left: 5vw;
}

.emaildialog-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.name-phone-fields {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 15px 0;
    flex-wrap: wrap;
    padding: 0 20px;
}

.email-item-fields {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 15px 0;
    flex-wrap: wrap;
    padding: 0 20px;
}

.labels-title {
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    user-select: none;
}

.emaildialog-input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    text-indent: 10px;
    border-radius: 5px;
}

.emaildialog-input.error {
    border-left: 10px solid #cc4a4a;
}

.emaildialog-labels {
    min-width: 225px;
    margin: 5px 5px;
    flex: 1 1
}

.emaildialog-email-label {
    flex: 1 1;
    margin: 0 5px;
}

.message-field {
    width: 100%;
    margin: 15px 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
}

.message-label {
    width: 100%;
    margin: 0 5px;
}

.email-text-area {
    width: 100%;
    border: none;
    outline: none;
    text-indent: 10px;
    padding-top: 10px;
    border-radius: 5px;
    resize: none;
}

.email-text-area.error {
    border-left: 10px solid #cc4a4a;
}

.text-div-upper-1,
.text-div-upper-2,
.text-div-bottom {
    text-align: left;
    letter-spacing: 2px;
    color: white;
    font-size: 18px;
    margin: 15px 0;
}

.text-div-upper-1,
.text-div-upper-2 {
    font-weight: 400;
}

.text-div-bottom {
    margin-top: 25px;
    font-weight: 700;
}

.close-button-email {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close-button-email:hover {
    transform: scale(1.3);
}

.item-field {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
}

.emaildialog-labels-select {
    margin: 5px 5px;
    flex: 1 1;
    min-width: 300px;
}

.emaildialog-task-autocomplete input {
    text-indent: 10px;
}

.emaildialog-item-preview {
    flex: 1 1;
    min-width: 300px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-left: 10px;
    color: white;
    font-size: 18px;
}

.emaildialog-item-preview>img {
    margin-right: 15px;
    width: auto;
    height: 40px;
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(2%) hue-rotate(201deg) brightness(104%) contrast(100%);
}

.task-progress {
    width: 100%;
    text-align: center;
}

.emaildialog-button-div {
    padding: 0 20px;
}

@media screen and (max-width: 960px) {

    .emaildialog-content-div {
        height: fit-content;
        flex-direction: column-reverse;
        min-height: auto;
        padding: 20px 5px;
    }

    .emaildialog-form-div {
        width: 100%;
        height: 80%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .emaildialog-text-div {
        width: 100%;
        height: 20%;
        padding: 20px;
        justify-content: center;
        align-items: center;
        margin: 50px 0 0 0;
    }

    .text-div-upper-1,
    .text-div-upper-2,
    .text-div-bottom {
        text-align: center;
    }

    .emaildialog-button-div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .emaildialog-item-preview {
        margin: 0;
    }

}