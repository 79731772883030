.contactos-main-div {
    height: calc(100vh - 200px);
    width: 100vw;
    min-height: 350px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    background-image: url('../../../assets/contactos/FUNDO_CONTACTOS-61.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px;
    animation: contacts 0.5s ease-in-out;
}

.contacts-form-div {
    height: 100%;
    width: 50%;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
}

.contacts-text-div {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
}

.contacts-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.contacts-name-phone-fields {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 15px 0;
    flex-wrap: wrap;
    padding: 0 20px;
}

.contacts-email-item-fields {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 15px 0;
    flex-wrap: wrap;
    padding: 0 20px;
}

.contacts-labels-title {
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    user-select: none;
}

.contacts-input {
    height: 40px;
    width: 100%;
    border: none;
    outline: none;
    text-indent: 10px;
}

.contacts-input.error {
    border-left: 10px solid #cc4a4a;
}

.contacts-labels {
    min-width: 225px;
    margin: 5px 5px;
    flex: 1 1
}

.contacts-email-label {
    flex: 1 1;
    margin: 0 5px;
}

.contacts-message-field {
    width: 100%;
    margin: 15px 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
}

.contacts-message-label {
    width: 100%;
    margin: 0 5px;
}

.contacts-email-text-area {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    resize: none;
}

.contacts-email-text-area.error {
    border-left: 10px solid #cc4a4a;
}

.contacts-text-div-title {
    color: white;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 18px;
}

.contacts-text-div-text {
    color: white;
    letter-spacing: 2px;
    margin: 25px 0;
    white-space: pre-line;
}

.contacts-text-div-bottom {
    color: white;
    display: flex;
    margin: 15px 0;
    align-items: center;
}

.contacts-text-div-bottom :first-child {
    font-weight: 700;
    width: 80px;
    border-right: 2px solid white;
    margin-right: 25px;
}

.contacts-labels-select {
    margin: 5px 5px;
    flex: 1 1;
    min-width: 300px;
}

.contacts-button-div {
    padding: 0 20px;
}

@keyframes contacts {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {

    .contactos-main-div {
        height: fit-content;
        flex-direction: column-reverse;
    }

    .contacts-form-div {
        width: 100%;
        height: 80%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .contacts-text-div {
        width: 100%;
        height: 20%;
        padding: 20px;
        justify-content: center;
        align-items: center;
        margin: 50px 0 0 0;
    }

    .contacts-text-div-text {
        text-align: center;
    }

    .contacts-button-div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contacts-text-div-bottom {
        width: 100%;
    }
}