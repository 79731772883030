.search-card-main-div {
    height: 125px;
    width: 95%;
    display: flex;
    margin: 20px;
    box-shadow: 1px 1px 4px 0px rgb(0, 0, 0, 50%);
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.search-card-img {
    height: 100%;
    width: auto;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
}

.search-card-info-div {
    flex: 1 1;
    padding: 10px 20px;
    overflow-y: auto;
}

.search-card-main-div:hover > .search-card-img {
    box-shadow: 3px 0px 5px 0px #00000093;
}

.search-card-main-div:hover {
    transform: scale(1.03);
}

.search-card-type {
    font-size: 15px;
    font-weight: 600;
}

.search-card-title {
    letter-spacing: 1px;
    color: var(--dark-beige);
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
}

.search-card-subtitle {
    font-size: 14px;
    font-weight: 600;
}

.search-card-date {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}

.search-type-date-div {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 960px) {

    .search-card-main-div {
        height: 300px;
        flex-direction: column;
        margin: 20px 0px;
    }

    .search-card-img {
        height: 100px;
        object-fit: cover;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .search-type-date-div {
        margin-bottom: 10px;
    }

}

