.news-list-main-div {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.no-results-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-results-text {
    margin-bottom: 25px;
    color: var(--dark-beige);
    font-weight: 600;
    font-size: 18px;
    text-align: center
}

.news-list-content-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.news-list-view-more-btn-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100px;
}

.news-list-content-title {
    text-align: center;
    color: var(--dark-beige);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.news-list-main-div::-webkit-scrollbar {
    width: 10px;
}

.news-list-main-div::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.news-list-main-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.5);
    background-clip: content-box;
}

.news-list-main-div::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: rgba(255,255,255,0.75);
}