.map-main-div {
	background-image: url('../../../assets/map/FUNDO_MAPA-64.jpg');
	height: calc(100vh - 200px);
	width: 100vw;
	min-height: 350px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	animation: mapStart 0.5s ease-in-out;
}

.map-left-div {
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.map-right-div {
	flex: 1 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.map-text {
	max-width: 300px;
	margin: 50px 0;
	text-align: left;
	font-size: 18px;
	font-weight: 300;
	letter-spacing: 2px;
	color: var(--very-light-beige);
}

.svg-map {
	width: 50vw;
    max-width: 600px;
	height: auto;
}

[class^="svg-map__location--pin"] {
	/*selecting all pins*/
	fill: #ffffff;
	outline: 0;
	pointer-events: none;
	-webkit-transform-origin: 50% 50%;
	-webkit-transition: .2s;
	transform-origin: 50% 50%;
	transform-box: fill-box;
	animation: pinAnimation 5s infinite ease-in-out;
}

[class^="svg-map__location--names"] {
	/*selecting all names*/
	fill: #ffffff;
	outline: 0;
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-webkit-transform-origin: 50% 50%;
	-webkit-transition: .2s;
	transform-origin: 50% 50%;
	transform-box: fill-box;
	pointer-events: none;
}

[class^="svg-map__location--id"] {
	/*selecting all districts*/
	fill: transparent;
	cursor: pointer;
	outline: 0;
	stroke: white;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	outline: 0;
}

.svg-map__location--pin--hidden {
	fill: none;
}

@keyframes mapStart {
	0% { opacity: 0.5}
	100% { opacity: 1;}
}

@keyframes pinAnimation {

	0%,
	100% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}

	50% {
		transform: scale(1.5);
		-webkit-transform: scale(1.5);
	}
}

.project-hover-main-div {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	transition: all 0.2s ease-in-out;
}

.project-hover-main-div.visible {
	opacity: 1;
	pointer-events: all;
	position: absolute;
	max-width: 600px;
	height: fit-content;
	border-radius: 10px;
	background-color: var(--very-light-beige);
	transition: all 0.2s ease-in-out;
}

.project-card-div {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px;
	border-radius: 10px;
	transition: all 0.2s ease-in-out;
}

.project-card-div:hover {
	background-color: var(--light-beige);
}

.project-card-image {
	height: 80px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	overflow: hidden;
}

.project-card-info {
	margin-left: 10px;
	flex: 1 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin-right: 25px;
}

.project-card-title {
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 2px;
	width: 100%;
	text-align: left;
}

.project-card-location {
	width: 100%;
	text-align: left;
}

.map-right-div-mobile {
	display: none;
}

@media screen and (max-width: 960px) {
	.map-main-div {
		flex-direction: column;
		overflow-y: auto;
	}

	.map-left-div {
		width: 100%;
		height: 400px;
		margin: 20px 0;
	}

	.map-left-div>img {
		width: 100px;
	}

	.map-text {
		margin: 10px;
		text-align: center;
	}

	.map-right-div {
		margin: 20px 0;
		display: none;
	}

	.map-right-div-mobile {
		display: flex;
		flex: 1 1;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}

	.svg-map {
		width: 90%;
	}

	.project-hover-main-div {
		display: none;
	}

	.mobile-map-top-bar {
		height: 25px;
		background-color: var(--medium-beige);
		display: flex;
		justify-content: flex-end;
	}
}