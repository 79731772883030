.home-mainDiv {
    height: fit-content;
}

.slider-container {
    height: calc(100vh - 200px);
    min-height: 550px;
    animation: sliderRise 0.5s ease-in-out;
}

.home-card {
    height: 100%;
    min-width: 100%;
}

.card-left-side {
    width: 50%;
    height: 100%;
    max-width: 850px;
    min-width: 700px;
    background-color: var(--green-bottle);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px 100px;
    mix-blend-mode: hard-light;
}

.card-title {
    color: white;
    font-size: 90px;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 86px;
    text-transform: uppercase;
}

.card-subtitle {
    color: white;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
    margin-top: 25px;
    text-indent: 10px;
    text-transform: uppercase;
}

.card-button {
    margin-top: 50px;
    width: 125px;
    height: 40px;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    text-align: center;
    line-height: 38px;
    text-transform: uppercase;
    transition: all 0.3s ease-out;
}

.card-button:hover {
    border-radius: 2rem;
    /* font-size: 20px; */
}

.card-counter {
    font-size: 86px;
    color: white;
    font-weight: 700;
    position: absolute;
    bottom: 0;
}

.arrow-left,
.arrow-right {
    position: absolute !important;
    top: 50%;
    transform: scale(3);
    color: white !important;
    transition: all 0.2s ease-in-out !important;
}

.arrow-right {
    right: 50px;
}

.arrow-left {
    left: 40px;
}

.arrow-left:hover,
.arrow-right:hover {
    transform: scale(3.5);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 2rem;
}

@keyframes sliderRise {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 100%;
        transform: scale(1);
    }
}

@media screen and (max-width: 960px) {

    .slider-container {
        min-height: auto;
    }

    .home-card {
        min-width: auto;
        width: 100%;
        display: flex;
        align-items: flex-end;
    }

    .card-left-side {
        min-width: auto;
        max-width: 100%;
        width: 100%;
        height: 40%;
        padding: 25px 25px;
    }

    .card-title {
        font-size: 30px;
        line-height: 10px;
        padding: 0;
    }

    .card-subtitle {
        text-indent: 0px;
        font-size: 18px;
    }

    .card-counter {
        display: none;
    }

    .card-button {
        margin-top: 20px;
    }

}