.loading-page-main-div {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-page-logo {
    width: auto;
    animation: logoRise 0.7s ease-in-out forwards;
}

.loading-page-text {
    margin-top: 20px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--dark-beige);
}

.loading-page-text::after {
    content: '';
    animation: loadingText 2s infinite ease-in-out forwards;
}

@keyframes logoRise {
   0% { height: 0; }
   100% { height: 100px;} 
}

@keyframes loadingText {
    0% { content: ''}
    25% { content: '.'}
    50% { content: '..'}
    75% { content: '...'}
    100% { content: '' }  
}