.apoiar-main-div {
    height: calc(100vh - 200px);
    width: 100vw;
    min-height: 350px;
    display: flex;
}

.apoiar-left-div {
    height: 100%;
    width: 20%;
    min-width: 350px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    animation: apoioSidePanel 0.7s ease-in-out forwards;
}

.apoiar-left-black-div {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.apoiar-left-text {
    color: white;
    font-size: 22px;
    margin-bottom: 50px;
    text-align: center;
}

.apoiar-left-button {
    height: 50px;
    width: 75%;
    border: 2px solid white;
    color: white;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 46px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.apoiar-left-button:hover {
    border-radius: 2rem;
    font-size: 20px;
}

.apoiar-right-div {
    width: 75%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 50px 0px;
    max-height: 100%;
}

.apoiar-right-div::-webkit-scrollbar {
    width: 10px;
}

.apoiar-right-div::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.apoiar-right-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.5);
    background-clip: content-box;
}

.apoiar-right-div::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: rgba(255,255,255,0.75);

}

.apoio-page-no-tasks {
    width: 100%;
    /* height: calc(100vh - 300px); */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--dark-beige) ;
}

@keyframes apoioSidePanel {
    0% { margin-left: -350px; opacity: 0;}
    100% { margin-left: 0; opacity: 1;}
}


@media screen and (max-width: 960px) {

    .apoiar-main-div {
        min-height: calc(100vh - 200px);
        flex-direction: column;
        height: fit-content;
    }

    .apoiar-left-div {
        width: 100%;
        height: 150px;
        min-width: auto;
    }

    .apoiar-left-text {
        margin-bottom: 20px;
    }

    .apoiar-left-black-div {
        padding: 10px;
    }

    .apoiar-right-div {
        width: 100%;
        height: fit-content;
        overflow-y: visible;
    }

    .apoio-page-no-tasks {
        text-align: center;
    
    }
}