.results-main-div {
	background-image: url('../../../assets/results/background_results.jpg');
	height: calc(100vh - 200px);
	width: 100vw;
	min-height: 350px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.results-title {
    color: var(--very-light-beige);
    font-size: 36px;
    letter-spacing: 4px;
    font-weight: 600;
    margin-bottom: 15px;
}

.results-subtitle {
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 400;
    color: var(--light-beige);
    margin-bottom: 25px;
    text-align: center;
}