.news-big-card-main-div {
    flex: 1 1;
    min-width: 450px;
    max-width: 800px;
    height: 200px;
    margin: 10px;
    background-color: var(--very-light-beige);
    display: flex;
    box-shadow: 5px 5px 8px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    animation: newsBigCard 0.5s ease-in-out forwards;
}

.news-big-card-image {
    height: 100%;
    width: 33%;
    position: relative;
}

.news-big-card-info {
    width: 66%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.news-big-card-date {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px 0;
}

.news-big-card-title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.news-big-card-location {
    font-size: 14px;
    margin-bottom: 20px;
}

.news-big-card-description {
    flex: 1 1;
    overflow-y: auto;
}

.news-big-card-main-div:hover .news-big-card-overlay {
    width: 100%;
    opacity: 1;
}

.news-big-card-main-div:hover {
    transform: scale(1.05);
}

.news-big-card-overlay {
    transition: all 0.2s ease-in-out;
    position: absolute;
    height: 100%;
    width: 0%;
    opacity: 0;
    background-color: var(--green-bottle);
    mix-blend-mode: hard-light;
}

@keyframes newsBigCard {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@media screen and (max-width: 960px) {
    
    .news-big-card-main-div {
        min-width: 100%;
    }

}