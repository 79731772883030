.news-small-card-main-div {
    width: 100%;
    max-width: 450px;
    height: 135px;
    margin: 20px 0;
    background-color: var(--very-light-beige);
    display: flex;
    box-shadow: 5px 5px 8px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.news-small-card-image {
    height: 100%;
    width: 33%;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.news-small-card-info {
    width: 66%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.news-small-card-date {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.news-small-card-title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.news-small-card-location {
    font-size: 14px;
}

.news-small-card-main-div:hover .news-small-card-overlay {
    width: 100%;
    opacity: 1;
}

.news-small-card-main-div:hover  {
    transform: scale(1.05);
    padding: 0 5px;
}

.news-small-card-overlay {
    transition: all 0.2s ease-in-out;
    position: absolute;
    height: 100%;
    width: 0%;
    opacity: 0;
    background-color: var(--green-bottle);
    mix-blend-mode: hard-light;
}