.project-arrows-right, .project-arrows-left {
    transform: scale(0);
    color: rgb(255, 255, 255) !important;
    position: absolute !important;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 100;
    padding: 0 !important;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}


.project-arrows-left:hover, .project-arrows-right:hover {
    transform: scale(3.5);
    background-color: rgba(0, 0, 0, 0.9) !important;
    border-radius: 2rem;
    transition: all 0.3s ease-in-out;
}

.project-arrows-left {
    left: 50px;
}

.project-arrows-right {
    right: 50px;
}

.project-arrows-right.show {
    opacity: 1;
    transform: scale(3);
}

.project-arrows-left.show {
    opacity: 1;
    transform: scale(3);
}

@media screen and (max-width: 960px) {
    .project-arrows-right.show, .project-arrows-left.show {
        display: none;
    }
}