.news-main-container {
    height: calc(100vh - 200px);
    width: 100vw;
    min-height: 500px;
    display: flex;
    flex-direction: column;
}

.news-action-bar {
    width: 100%;
    height: 90px;
    background-color: var(--light-beige);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 25px;
    animation: newsToolbar 1s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.news-content-div {
    flex: 1 1;
    position: relative;
    overflow-y: auto;
    scroll-behavior: smooth;
    min-height: 515px;
}

.news-search-div {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 20px;
}

.news-search-input {
    width: 100%;
    min-width: 300px;
    height: 40px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    text-indent: 50px;
    outline: none;
    transition: all 0.3s ease-in-out;
}

.news-search-input:hover,
.news-search-input:focus  {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.news-search-input::placeholder {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.news-search-icon {
    position: absolute;
    left: 10px;
    top: 5px;
}

.news-search-arrow {
    height: 40px;
    width: 40px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news-arrow-icon {
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

.news-arrow-icon:hover {
    transform: scale(1.5);
}

.news-top-btn {
    height: 40px;
    width: fit-content;
    min-width: 175px;
    border: 2px solid white;
    text-transform: uppercase;
    color: white;
    letter-spacing: 4px;
    padding: 0 20px;
    font-weight: 700;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin: 0 20px;
}

.news-top-btn:hover {
    border-radius: 20px;
}

.news-top-btn.active {
    border-radius: 20px;
    background-color: white;
    color: var(--light-beige);
}

.search-helper-text {
    position: absolute;
    color: white;
    bottom: -20px;
    font-size: 14px;
    font-weight: 600;
    
}


@keyframes newsToolbar {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@media screen and (max-width: 960px) {
    
    .news-action-bar {
        gap: 5px;
        padding: 5px;
        height: 150px;
        flex-direction: column;
        justify-content: space-around;
        /* position: sticky;
        top: 0; */
    }

    .news-search-div {
        width: 75%;
    }

    .news-top-btn {
        width: 75%;
        height: 35px;
    }

    .news-search-input {
        min-width: 200px;
    }
}
