.about-mainDiv {
    height: fit-content;
    min-height: 600px;
    position: relative;
}

.about-card {
    height: 100%;
    min-width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.lugar-div {
    height: 1400px;
    animation: aboutSlidersRise 0.5s ease-in-out;
}

.lugar-slider {
    height: 50%;
}

.lugar-info {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-image: url('../../../assets/about/apoio.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.lugar-info-title {
    text-transform: uppercase;
    color: white;
    font-size: 32px;
    width: 100%;
    text-align: left;
    height: 20%;
    max-width: 1200px;
}

.lugar-info-middle {
    height: 40%;
    width: 80%;
    max-width: 1000px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.lugar-info-middle>div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 80%;
    color: white;
    text-transform: uppercase;
}

.lugar-info-middle-icon {
    height: 75px;
    width: auto;
}

.lugar-info-bottom {
    height: 40%;
    width: 80%;
    max-width: 1000px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.lugar-info-bottom-div {
    height: 50%;
    width: 30%;
    border: 3px solid white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lugar-info-bottom-div-number {
    color: white;
    font-size: 36px;
}

.lugar-info-bottom-div-text {
    color: white;
    font-size: 18px;
    text-align: center;
}

.am-div,
.adiram-div {
    height: 800px;
}

.am-div {
    animation: aboutSlidersRise 1s ease-in-out;
}

.adiram-div {
    animation: aboutSlidersRise 1.5s ease-in-out;
}

.about-lugar-left-side {
    width: 60%;
    height: 700px;
    max-width: 1000px;
    min-width: 700px;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 100px;
    z-index: 5;
}

.about-am-right-side {
    width: 60%;
    height: 800px;
    max-width: 1000px;
    min-width: 700px;
    position: absolute;
    top: 1400px;
    background-color: rgba(5, 39, 5, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 100px 15px 100px;
    z-index: 5;
    right: 0;
}

.about-adiram-left-side {
    width: 60%;
    height: 800px;
    max-width: 1000px;
    min-width: 700px;
    position: absolute;
    top: 2200px;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 100px;
    z-index: 5;
    left: 0;
}

.lugar-card-title {
    color: white;
    font-size: 50px;
    font-weight: 600;
}

.lugar-card-subtitle {
    color: white;
    font-size: 30px;
    font-weight: 200;
    /* text-transform: uppercase; */
    width: 90%;
    word-spacing: 5px;
    line-height: 32px;
    margin-bottom: 50px;
}

.lugar-card-description {
    color: white;
    font-size: 18px;
    /* font-weight: 300; */
    white-space: pre-line;
    overflow-y: auto;
    min-height: 200px;
    scrollbar-width: auto;
    /* firefox */
}

@keyframes aboutSlidersRise {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.lugar-card-description::-webkit-scrollbar {
    width: 10px;
}

.lugar-card-description::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.lugar-card-description::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    background-clip: content-box;
}

.lugar-card-description::-webkit-scrollbar-thumb:hover {
    border: 3px solid transparent;
    background-color: rgba(255, 255, 255, 0.75);

}

.about-am-right-side-locations {
    width: 100%;
}

.about-am-partners {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 125px;
}

.about-am-partners>p {
    color: white;
    letter-spacing: 2px;
    font-size: 16px;
    margin: 2px 0;
}

.about-am-right-side-social {
    width: 100%;
    margin-top: 15px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.about-am-right-side-social>img {
    height: 30px;
    margin-right: 20px;
    cursor: pointer;
    filter: invert(1);
    transition: all 0.2s ease-in-out;
}

.about-am-right-side-social>p {
    cursor: pointer;
    color: white;
    letter-spacing: 2px;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
}

.about-am-right-side-social>p:hover {
    letter-spacing: 3px;
}

.about-am-right-side-social>img:hover {
    filter: invert(0.7);
}

.inline-link:hover {
    color: var(--medium-beige) !important;
}

.scroll-up-btn {
    display: none;
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 10;
    cursor: pointer;
    filter: drop-shadow(1px 1px 3px black);
    transition: all 0.3s ease-in-out;
}

.scroll-up-btn:hover {
    transform: scale(1.2);
}

.scroll-up-btn.show {
    display: flex;
}

@media screen and (max-width:1650px) and (min-width: 960px) {

    .lugar-card-title {
        font-size: 42px;
    }

    .lugar-card-subtitle {
        font-size: 28px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 960px) {

    .about-adiram-left-side {
        min-width: auto;
        width: 100%;
        padding: 20px 20px;
    }

    .about-am-right-side {
        min-width: auto;
        width: 100%;
        padding: 20px 20px;
    }

    .about-lugar-left-side {
        min-width: auto;
        width: 100%;
        padding: 20px 20px;
    }

    .lugar-card-subtitle {
        margin-bottom: 20px;
        font-size: 26px;
    }

    .lugar-card-description {
        font-size: 16px;
        max-height: 500px;
    }

    .lugar-info {
        padding: 20px
    }

    .lugar-info-bottom {
        width: 100%;
        justify-content: center;
    }

    .lugar-info-bottom-div {
        margin: 0px 10px;
        width: 50%;
    }

    .lugar-info-middle {
        width: 100%;
    }

    .about-am-partners>p {
        color: white;
        letter-spacing: 0px;
        font-size: 14px;
        margin: 0px 0;
    }

    .about-am-partners {
        height: 125px;
    }

    .lugar-card-title {
        color: white;
        font-size: 42px;
        font-weight: 600;
        line-height: 42px;
    }

    .about-am-right-side-social>p {
        letter-spacing: 1px;
        font-size: 16px;
    }

    .about-am-right-side-social {
        flex-wrap: wrap;
    }

}