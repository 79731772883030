.news-themes-main-div {
    width: 100%;
    height: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    padding: 20px;
}

.theme-card-main-div {
    /* width: 400px; */
    max-width: 450px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 20px 30px;
    transition: all 0.2s ease-in-out;
    animation: newsThemes 1s ease-in-out;
}

.theme-card-top {
    width: 100%;
    background-color: var(--light-beige);
    height: 50px;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.theme-card-bottom {
    flex: 1 1;
}

.theme-card-title {
    width: 100%;
    height: 40px;
    text-align: center;
    margin-bottom: -20px;
    line-height: 40px;
    color: var(--dark-beige);
    letter-spacing: 1px;
}

.theme-card-main-div:hover {
    transform: scale(1.02);
}

@keyframes newsThemes {
    0% { opacity: 0;}
    100% { opacity: 1;}
}