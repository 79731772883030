.projects-details-main-div {
    height: fit-content;
    min-height: 600px;
}

.main-slider-mobile-div {
    height: 100%;
}

.projects-details-upper-div,
.projects-details-bottom-div {
    height: 600px;
    animation: projectDetailsSlider 0.7s ease-in-out;
}

.project-card {
    height: 100%;
    min-width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.project-details-card-title {
    color: white;
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
}

.project-details-card-subtitle {
    color: white;
    font-size: 26px;
    font-weight: 200;
    text-transform: uppercase;
    width: 90%;
    word-spacing: 5px;
    line-height: 32px;
    margin-bottom: 50px;
}

.project-details-card-description-upper,
.project-details-card-description-bottom {
    text-shadow: 0px 0px 0px white;
    color: white;
    font-size: 16px;
    font-weight: 300;
    /* max-height: 55%; */
    white-space: pre-line;
    overflow-y: auto;
    scrollbar-width: auto;
    /* firefox */
}

.project-details-card-description-bottom::-webkit-scrollbar,
.project-details-card-description-upper::-webkit-scrollbar {
    width: 10px;
}

.project-details-card-description-bottom::-webkit-scrollbar-track,
.project-details-card-description-upper::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.project-details-card-description-bottom::-webkit-scrollbar-thumb,
.project-details-card-description-upper::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    background-clip: content-box;
}

.project-details-card-description-bottom::-webkit-scrollbar-thumb:hover,
.project-details-card-description-upper::-webkit-scrollbar-thumb:hover {
    border: 3px solid transparent;
    background-color: rgba(255, 255, 255, 0.75);

}

.text-link {
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

.text-link:hover {
    color: var(--medium-beige);
}


.project-details-card-description-title {
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 24px;
    text-transform: uppercase;
}

.view-360-button {
    height: 85%;
    width: 125px;
    border: 2px solid white;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.apoiar-button-upper,
.apoiar-button-bottom {
    height: 85%;
    width: 200px;
    border: 2px solid white;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.apoiar-button-mobile {
    height: 85%;
    width: 200px;
    border: 2px solid white;
    text-decoration: none;
    display: none;
    font-size: 16px;
    font-weight: 600;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.apoiar-button-upper:hover,
.apoiar-button-bottom:hover {
    border-radius: 2rem;
    font-size: 17px;
}

.view-360-button:hover {
    border-radius: 2rem;
    font-size: 17px;
}

.share-social-button {
    height: 60%;
    width: 50px;
    cursor: pointer;
    text-align: center;
}

.share-social-button>img {
    width: auto;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.share-social-button>img:hover {
    transform: scale(1.3);
}

.share-icon {
    height: 100%;
}

.projects-details-top-bar {
    width: 100%;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    animation: projectDetailsTopBar 0.7s ease-in-out;
}

.projects-details-top-bar.sticky {
    width: 100%;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 55;
    top: 0;
}

.projects-details-project-name {
    height: 100%;
    padding-left: 30px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.projects-details-top-bar-icons {
    width: fit-content;
    padding-top: 18px;
    margin-left: 15px;
    height: 100%;
}

.projects-details-top-bar-icons>* {
    margin: 0 10px;
}

.projects-details-top-bar-actions {
    flex: 1 1;
    display: flex;
    height: 50px;
    justify-content: flex-end;
    align-items: center;
}

.projects-details-top-bar-actions>* {
    margin: 0 15px;
}

.upper-details-div-test {
    width: 50%;
    height: 600px;
    max-width: 850px;
    min-width: 700px;
    position: absolute;
    top: 200px;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 100px 25px;
    z-index: 5;
    transition: height 0.5s ease-in-out;
    animation: projectDetailsSlider 0.7s ease-in-out forwards;
}

.upper-details-div-test.minimized {
    width: 50%;
    height: 50px;
    max-width: 850px;
    min-width: 700px;
    position: absolute;
    top: 200px;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 100px 25px;
    z-index: 5;
    transition: height 0.5s ease-in-out;
}

.minimize-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.bottom-details-div-test {
    width: 50%;
    height: 600px;
    max-width: 850px;
    min-width: 700px;
    position: absolute;
    top: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px 100px 15px 100px;
    z-index: 5;
    right: 0;
    transition: height 0.5s ease-in-out;
    animation: projectDetailsSlider 0.7s ease-in-out forwards;
}

.bottom-details-div-test.minimized {
    width: 50%;
    height: 50px;
    max-width: 850px;
    min-width: 700px;
    position: absolute;
    top: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 100px 15px 100px;
    z-index: 5;
    right: 0;
    transition: height 0.5s ease-in-out;
}

.bottom-details-background-test {
    position: absolute;
    right: 0;
    top: 800px;
    width: 50%;
    background-color: var(--green-bottle);
    mix-blend-mode: hard-light;
    height: 600px;
    opacity: 0;
    z-index: 2;
    min-width: 700px;
    transition: height 0.5s ease-in-out;
    animation: projectDetailsSlider 0.7s ease-in-out forwards;
}

.bottom-details-background-test.minimized {
    position: absolute;
    right: 0;
    top: 800px;
    width: 50%;
    background-color: var(--green-bottle);
    mix-blend-mode: hard-light;
    height: 50px;
    z-index: 2;
    transition: height 0.5s ease-in-out;
}

.bottom-details-text-area-test {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 5;
}

.upper-details-text-area-test {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 5;
}

.projects-details-solo-div {
    min-height: 600px;
    height: calc(100vh - 300px);
    animation: projectDetailsSlider 0.7s ease-in-out;
}

.solo-details-div {
    width: 50%;
    height: calc(100vh - 300px);
    max-width: 850px;
    min-width: 700px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    top: 200px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px 100px 15px 100px;
    z-index: 5;
    right: 0;
    transition: all 0.5s ease-in-out;
    animation: projectDetailsSlider 0.7s ease-in-out forwards;
}

.solo-details-div.minimized { 
    height: 50px;
    min-height: 0;
    padding: 10px 100px 25px;
    transition: all 0.5s ease-in-out;
}

@keyframes projectDetailsTopBar {
    0% { height: 0; opacity: 0.5;}
    100% { height: 100px; opacity: 1;}
}

@keyframes projectDetailsSlider {
    0% { opacity: 0;}
    100% { opacity: 1;} 
 }

@media screen and (max-width:1300px) and (min-width: 960px) {

    .upper-details-div-test {
        top: 275px;
    }

    .bottom-details-div-test {
        top: 875px;
    }

    .bottom-details-background-test {
        top: 875px
    }

    .upper-details-div-test.minimized {
        top: 275px;
    }

    .bottom-details-background-test.minimized {
        top: 875px;
    }

    .bottom-details-div-test.minimized {
        top: 875px;
    }

    .solo-details-div { 
        top: 275px;
    }
}

@media screen and (max-width: 960px) {

    .upper-details-div-test {
        width: 100%;
        min-width: 100%;
        padding: 45px 15px;
        position: unset;
        height: fit-content;
    }

    .bottom-details-div-test {
        width: 100%;
        min-width: 100%;
        padding: 45px 15px;
        position: unset;
        background-color: var(--green-bottle);
        height: fit-content;
    }

    .solo-details-div {
        width: 100%;
        min-width: 100%;
        padding: 45px 15px;
        position: unset;
        height: fit-content;
        min-height: 350px;
    }

    .bottom-details-background-test {
        display: none;
    }

    .project-details-card-subtitle {
        margin-bottom: 20px;
        font-size: 18px;
    }

    .project-details-card-title {
        line-height: 20px;
        font-size: 26px;
    }


    .main-slider-mobile-div {
        height: 45vw;
        min-height: 225px;
    }

    .projects-details-upper-div-test,
    .projects-details-bottom-div-test {
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    .projects-details-top-bar {
        width: 100%;
        min-height: 150px;
        height: fit-content;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .projects-details-top-bar.sticky {
        width: 100%;
        height: fit-content;
        min-height: 150px;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        align-items: flex-start;
        position: sticky;
        z-index: 55;
        top: 0;
    }

    .projects-details-project-name {
        height: fit-content;
        width: 100%;
        display: flex;
        padding: 10px 0 0 10px;
        flex-direction: column;
        justify-content: center;
    }

    .projects-details-top-bar-icons {
        width: fit-content;
        padding-top: 0;
        margin-left: 5px;
        height: fit-content;
    }

    .projects-details-top-bar-icons>* {
        margin: 0 10px;
    }

    .projects-details-top-bar-actions {
        flex: 0 0;
        display: flex;
        width: 100%;
        height: 50px;
        margin: 10px 0;
        justify-content: space-evenly;
        align-items: center;
    }

    .projects-details-top-bar-actions>* {
        margin: 0 10px;
        width: fit-content;
        padding: 0 5px;
        height: 35px;
    }

    .apoiar-button-bottom {
        display: flex;
    }

    .share-icon {
        height: 50px;
    }

    .share-social-button>img:hover {
        transform: scale(1);
    }

    .apoiar-button-mobile {
        display: flex;
    }

    .project-details-icons {
        justify-content: center;
    }

    .project-details-buttons {
        justify-content: space-around;
        margin-top: 20px;
    }

    .main-slider-mobile-div {
        height: 200px;
    }

    .projects-details-upper-div,
    .projects-details-bottom-div {
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    .projects-details-solo-div {
        min-height: 0;
        height: fit-content;
    }
}